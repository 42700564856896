import axios from "axios";
import ACCESS_ENUM from "@/access/accessEnum";
import { useLoginUserStore } from "@/store/userStore";

// 是否是开发环境
export const isDev = process.env.NODE_ENV === "development";

const myAxios = axios.create({
  baseURL: isDev
    ? "http://localhost:8080"
    : "https://funtest-127648-8-1331347804.sh.run.tcloudbase.com",
  timeout: 60000,
  withCredentials: true,
});

// 请求拦截器
myAxios.interceptors.request.use(
  function (config) {
    // 确保请求携带 Cookie
    return config;
  },
  function (error) {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 响应拦截器

myAxios.interceptors.response.use(
  function (response) {
    // 响应成功逻辑，可根据需要处理其他返回值
    return response;
  },
  (error) => {
    // 处理响应错误（例如 401 未授权）
    if (error.response && error.response.status === 401) {
      const loginUserStore = useLoginUserStore();
      console.error("未授权，请重新登录");
      // 清除本地存储中的用户数据
      localStorage.removeItem("hasFetched");
      loginUserStore.setLoginUser({ userRole: ACCESS_ENUM.NOT_LOGIN });
      loginUserStore.hasFetched = false;

      // 重定向到登录页
      window.location.href = "/user/login"; // 假设登录页路径为 /user/login
    }
    return Promise.reject(error);
  }
);

export default myAxios;
