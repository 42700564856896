import router from "@/router";
import { useLoginUserStore } from "@/store/userStore";
import ACCESS_ENUM from "@/access/accessEnum";
import checkAccess from "@/access/checkAccess";

router.beforeEach(async (to, from, next) => {
  const loginUserStore = useLoginUserStore();
  const hasFetched = loginUserStore.hasFetched;
  let { loginUser } = loginUserStore;

  // 如果是登录页或注册页，跳过主页相关逻辑
  if (to.path === "/user/login" || to.path === "/user/register") {
    return next(); // 直接跳转
  }

  // 如果还未获取过用户信息，则尝试获取用户信息
  if (!hasFetched) {
    await loginUserStore.fetchLoginUser();
    loginUser = loginUserStore.loginUser;
  }

  // 获取目标路由所需权限
  const requiredAccess: any = to.meta?.access ?? ACCESS_ENUM.NOT_LOGIN;

  if (requiredAccess !== ACCESS_ENUM.NOT_LOGIN) {
    // 检查用户是否已登录
    const isLoggedIn =
      loginUser?.userRole && loginUser.userRole !== ACCESS_ENUM.NOT_LOGIN;

    if (!isLoggedIn) {
      // 如果未登录，跳转到登录页，并记录重定向路径
      return next(`/user/login?redirect=${to.fullPath}`);
    }

    // 检查用户权限是否足够
    if (!checkAccess(loginUser, requiredAccess)) {
      // 权限不足，跳转到无权限页面
      return next("/noAuth");
    }
  }

  // 如果无需登录或权限检查，直接放行
  next();
});
