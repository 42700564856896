import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "appDetailPage" }
const _hoisted_2 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_3 = { style: { display: 'flex', alignItems: 'center' } }

import { computed, ref, watchEffect } from "vue";
import API from "@/api";
import { getAppVoByIdUsingGet } from "@/api/appController";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import { useLoginUserStore } from "@/store/userStore";
import { APP_SCORING_STRATEGY_MAP, APP_TYPE_MAP } from "@/constant/app";
import utc from "dayjs/plugin/utc";
import ShareModal from "@/components/ShareModal.vue";

interface Props {
  id: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppDetailPage',
  props: {
    id: { default: () => {
    return "";
  } }
  },
  setup(__props: any) {

const props = __props;

const router = useRouter();
const data = ref<API.AppVO>({});
const isLoading = ref(true);

dayjs.extend(utc);

// 获取登录用户
const loginUserStore = useLoginUserStore();
let loginUserId = loginUserStore.loginUser?.id;

// 是否为本人创建
const isMy = computed(() => {
  return loginUserId && loginUserId === data.value.userId;
});

/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.id) {
    return;
  }
  const res = await getAppVoByIdUsingGet({
    id: props.id as any,
  });
  if (res.data.code === 0) {
    data.value = res.data.data as any;
    isLoading.value = false;
  } else {
    message.error("获取数据失败，" + res.data.message);
    isLoading.value = false;
  }
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

// 分享弹窗的引用
const shareModalRef = ref();

// 分享链接
const shareLink = `${window.location.protocol}//${window.location.host}/app/detail/${props.id}`;

// 分享
const doShare = (e: Event) => {
  if (shareModalRef.value) {
    shareModalRef.value.openModal();
  }
  // 阻止冒泡，防止跳转到详情页
  e.stopPropagation();
};

return (_ctx: any,_cache: any) => {
  const _component_icon_loading = _resolveComponent("icon-loading")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createTextVNode(" 加载中，请稍候... ")),
          _createVNode(_component_icon_loading)
        ]))
      : (_openBlock(), _createBlock(_component_a_card, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { style: {"margin-bottom":"16px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  flex: "auto",
                  class: "content-wrapper"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", null, _toDisplayString(data.value.appName), 1),
                    _createElementVNode("p", null, _toDisplayString(data.value.appDesc), 1),
                    _createElementVNode("p", null, "应用 id：" + _toDisplayString(data.value.id), 1),
                    _createElementVNode("p", null, "应用类型：" + _toDisplayString(_unref(APP_TYPE_MAP)[data.value.appType]), 1),
                    _createElementVNode("p", null, "评分策略：" + _toDisplayString(_unref(APP_SCORING_STRATEGY_MAP)[data.value.scoringStrategy]), 1),
                    _createElementVNode("p", null, [
                      _createVNode(_component_a_space, null, {
                        default: _withCtx(() => [
                          _cache[5] || (_cache[5] = _createTextVNode(" 作者： ")),
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_a_avatar, {
                              size: 40,
                              "image-url": data.value.user?.userAvatar,
                              style: { marginRight: '10px' }
                            }, null, 8, ["image-url"]),
                            _createVNode(_component_a_typography_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(data.value.user?.userName ?? "匿名用户"), 1)
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("p", null, " 创建时间：" + _toDisplayString(_unref(dayjs).utc(data.value.createTime).format("YYYY-MM-DD HH:mm:ss")), 1),
                    _createVNode(_component_a_space, { size: "medium" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          onClick: _cache[0] || (_cache[0] = () => _unref(router).push(`/answer/do/${_ctx.id}`))
                        }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode(" 开始答题 ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_a_button, { onClick: doShare }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("分享应用")
                          ])),
                          _: 1
                        }),
                        (isMy.value)
                          ? (_openBlock(), _createBlock(_component_a_button, {
                              key: 0,
                              onClick: _cache[1] || (_cache[1] = () => _unref(router).push(`/add/question/${_ctx.id}`))
                            }, {
                              default: _withCtx(() => _cache[8] || (_cache[8] = [
                                _createTextVNode(" 设置题目 ")
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (isMy.value)
                          ? (_openBlock(), _createBlock(_component_a_button, {
                              key: 1,
                              onClick: _cache[2] || (_cache[2] = () => _unref(router).push(`/add/scoring_result/${_ctx.id}`))
                            }, {
                              default: _withCtx(() => _cache[9] || (_cache[9] = [
                                _createTextVNode(" 设置评分 ")
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (isMy.value)
                          ? (_openBlock(), _createBlock(_component_a_button, {
                              key: 2,
                              onClick: _cache[3] || (_cache[3] = () => _unref(router).push(`/add/app/${_ctx.id}`))
                            }, {
                              default: _withCtx(() => _cache[10] || (_cache[10] = [
                                _createTextVNode(" 修改应用 ")
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { flex: "320px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_image, {
                      width: "100%",
                      src: data.value.appIcon
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })),
    _createVNode(ShareModal, {
      link: shareLink,
      title: "应用分享",
      ref_key: "shareModalRef",
      ref: shareModalRef
    }, null, 512)
  ]))
}
}

})