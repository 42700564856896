<template>
  <a-row id="globalHeader" align="center" :wrap="false">
    <a-col flex="auto">
      <a-menu
        mode="horizontal"
        :selected-keys="selectedKeys"
        @menu-item-click="handleMenuClick"
      >
        <a-menu-item
          key="0"
          :style="{ padding: 0, marginRight: '30px' }"
          disabled
        >
          <a-space>
            <div class="titleBar">
              <img class="logo" src="../assets/logo.png" />
              <div class="title">知巢</div>
            </div>
          </a-space>
        </a-menu-item>
        <a-menu-item
          style="margin-right: 20px"
          class="menu-item-font"
          v-for="item in visibleRoutes"
          :key="item.path"
        >
          {{ item.name }}
        </a-menu-item>
      </a-menu>
    </a-col>
    <a-col flex="150px">
      <div v-if="isLogin">
        <a-space>
          <a-dropdown trigger="hover">
            <a-avatar
              v-if="loginUserStore.loginUser.userAvatar"
              trigger-type="mask"
              shape="square"
              :size="64"
              @click="handleLogout"
            >
              <img alt="登出" :src="loginUserStore.loginUser.userAvatar" />
              <template #trigger-icon>
                <icon-export :size="32" />
              </template>
            </a-avatar>
            <a-skeleton v-else :animation="true">
              <a-skeleton-shape size="large" />
            </a-skeleton>
            <template #content>
              <a-doption style="color: #2ad0f9" @click="userCenterPage">
                <template #icon>
                  <icon-list />
                </template>
                个人中心
              </a-doption>
              <a-doption style="color: orange" @click="userUpdatePasswordPage">
                <template #icon>
                  <icon-eye-invisible />
                </template>
                更改密码
              </a-doption>
              <a-doption style="color: #fa3030" @click="handleLogout">
                <template #icon>
                  <icon-export />
                </template>
                退出登录
              </a-doption>
            </template>
          </a-dropdown>
          <UserCenter ref="userCenterRef" />
          <UpdateUserPassword ref="userUpdatePasswordRef" />
        </a-space>
      </div>
      <div v-else>
        <a-button
          class="loginFontSize"
          type="primary"
          href="/user/login"
          size="large"
          style="font-size: large"
        >
          登录
        </a-button>
      </div>
    </a-col>
  </a-row>
</template>

<script setup lang="ts">
import { routes } from "@/router/routes";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { useLoginUserStore } from "@/store/userStore";
import { userLogoutUsingPost } from "@/api/userController";
import message from "@arco-design/web-vue/es/message";
import UserCenter from "@/components/UserCenter.vue";
import UpdateUserPassword from "@/components/UpdateUserPassword.vue";
import ACCESS_ENUM from "@/access/accessEnum";

const router = useRouter();
const userCenterRef = ref();
const userUpdatePasswordRef = ref();
const loginUserStore = useLoginUserStore();
const isLogin = ref(localStorage.getItem("hasFetched"));

// 当前选中的菜单项
const selectedKeys = ref(["/"]);
// 路由跳转时自动更新选中的菜单项
router.afterEach((to) => {
  selectedKeys.value = [to.path];
});
// 过滤掉隐藏在菜单中的路由
const visibleRoutes = routes.filter((item) => item.meta?.hideInMenu !== true);
// 点击跳转到对应的菜单页面
const handleMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};

// 退出登录
const handleLogout = async () => {
  try {
    // 调用后端的退出登录接口
    const res = await userLogoutUsingPost();

    if (res.data.code === 0) {
      message.success("登出成功，正在跳转...");

      // 清除本地存储的用户数据和状态
      localStorage.removeItem("hasFetched");

      // 重置 Pinia 中的用户状态
      loginUserStore.setLoginUser({ userRole: ACCESS_ENUM.NOT_LOGIN });
      loginUserStore.hasFetched = false;

      // 延时跳转至主页
      setTimeout(() => {
        router.replace("/").then(() => {
          // 刷新页面以确保清理 Session 状态
          window.location.reload();
        });
      }, 600);
    } else {
      message.error("登出失败：" + (res.data.message || "未知错误"));
    }
  } catch (error) {
    console.error("登出错误:", error);
    message.error("登出失败，请稍后重试");
  }
};

// 个人中心
const userCenterPage = (e: Event) => {
  if (userCenterRef.value) {
    userCenterRef.value.openModal();
  }
  // 阻止冒泡
  e.stopPropagation();
};

// 修改密码
const userUpdatePasswordPage = (e: Event) => {
  if (userUpdatePasswordRef.value) {
    userUpdatePasswordRef.value.openModal();
  }
  // 阻止冒泡
  e.stopPropagation();
};
</script>

<style scoped>
#globalHeader {
}

.menu-item-font {
  font-size: 24px;
}

.titleBar {
  display: flex;
  align-items: center;
}

.logo {
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

.title {
  color: black;
  font-weight: bold;
  font-size: 32px;
}
</style>
