<template>
  <!-- 加载中提示 -->
  <div v-if="isLoading" class="loading-container">
    加载中，请稍候...
    <icon-loading />
  </div>
  <div v-else :style="{ display: 'flex' }">
    <a-card class="appCard" hoverable @click="doCardClick">
      <template #actions>
        <span class="icon-hover" @click="doShare"> <IconShareInternal /> </span>
      </template>
      <template #cover>
        <img
          :style="{ width: '100%', height: '100%' }"
          :alt="app.appName"
          :src="app.appIcon"
        />
      </template>
      <a-card-meta :title="app.appName" :description="app.appDesc">
        <template #avatar>
          <div
            :style="{ display: 'flex', alignItems: 'center', color: '#1D2129' }"
          >
            <a-avatar
              shape="square"
              :size="36"
              :image-url="app.user?.userAvatar"
              :style="{ marginRight: '8px' }"
            />
            <a-typography-text
              >{{ app.user?.userName ?? "匿名用户" }}
            </a-typography-text>
          </div>
        </template>
      </a-card-meta>
    </a-card>
    <ShareModal :link="shareLink" title="应用分享" ref="shareModalRef" />
  </div>
</template>

<script setup lang="ts">
import { IconShareInternal } from "@arco-design/web-vue/es/icon";
import API from "@/api";
import { defineProps, ref, withDefaults } from "vue";
import { useRouter } from "vue-router";
import ShareModal from "@/components/ShareModal.vue";

interface Props {
  app: API.AppVO;
  isLoading?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  app: () => {
    return {};
  },
  isLoading: false,
});

const router = useRouter();
const doCardClick = () => {
  router.push(`/app/detail/${props.app.id}`);
};

// 分享弹窗的引用
const shareModalRef = ref();

// 分享链接
const shareLink = `${window.location.protocol}//${window.location.host}/app/detail/${props.app.id}`;

// 分享
const doShare = (e: Event) => {
  if (shareModalRef.value) {
    shareModalRef.value.openModal();
  }
  // 阻止冒泡，防止跳转到详情页
  e.stopPropagation();
};
</script>
<style scoped>
.appCard {
  margin: 10px;
  transition-property: all;
}

.appCard:hover {
  transform: translateY(-8px);
}

/**
 * 分享按钮
 */
.icon-hover {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 20%;
  transition: all 0.1s;
  transition-property: all;
}

/*
 * 分享按钮标识
 */
.icon-hover:hover {
  background-color: rgb(var(--gray-2));
  transition-property: all;
}
</style>
