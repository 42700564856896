import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "answerResultPage" }
const _hoisted_2 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_3 = { style: { display: 'flex', alignItems: 'center' } }

import { ref, watchEffect } from "vue";
import API from "@/api";
import { getUserAnswerVoByIdUsingGet } from "@/api/userAnswerController";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import { APP_SCORING_STRATEGY_MAP, APP_TYPE_MAP } from "@/constant/app";

interface Props {
  id: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AnswerResultPage',
  props: {
    id: { default: () => {
    return "";
  } }
  },
  setup(__props: any) {

const props = __props;

const data = ref<API.UserAnswerVO>({});
const isLoading = ref<boolean>(true);
/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.id) {
    return;
  }
  const res = await getUserAnswerVoByIdUsingGet({
    id: props.id as any,
  });
  if (res.data.code === 0) {
    data.value = res.data.data as any;
    isLoading.value = false;
  } else {
    message.error("获取数据失败，" + res.data.message);
    isLoading.value = false;
  }
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

return (_ctx: any,_cache: any) => {
  const _component_icon_loading = _resolveComponent("icon-loading")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createTextVNode(" 加载中，请稍候... ")),
          _createVNode(_component_icon_loading)
        ]))
      : (_openBlock(), _createBlock(_component_a_card, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { style: {"margin-bottom":"16px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  flex: "auto",
                  class: "content-wrapper"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", null, _toDisplayString(data.value.resultName), 1),
                    _createElementVNode("p", null, "结果描述：" + _toDisplayString(data.value.resultDesc), 1),
                    _createElementVNode("p", null, "结果 id：" + _toDisplayString(data.value.resultId), 1),
                    _createElementVNode("p", null, "结果得分：" + _toDisplayString(data.value.resultScore), 1),
                    _createElementVNode("p", null, "应用 id：" + _toDisplayString(data.value.appId), 1),
                    _createElementVNode("p", null, "应用类型：" + _toDisplayString(_unref(APP_TYPE_MAP)[data.value.appType]), 1),
                    _createElementVNode("p", null, "评分策略：" + _toDisplayString(_unref(APP_SCORING_STRATEGY_MAP)[data.value.scoringStrategy]), 1),
                    _createElementVNode("p", null, [
                      _createVNode(_component_a_space, null, {
                        default: _withCtx(() => [
                          _cache[1] || (_cache[1] = _createTextVNode(" 答题人： ")),
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_a_avatar, {
                              size: 24,
                              "image-url": data.value.user?.userAvatar,
                              style: { marginRight: '8px' }
                            }, null, 8, ["image-url"]),
                            _createVNode(_component_a_typography_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(data.value.user?.userName ?? "匿名用户"), 1)
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("p", null, "答题时间：" + _toDisplayString(_unref(dayjs)().format("YYYY-MM-DD HH:mm:ss")), 1),
                    _createVNode(_component_a_space, { size: "medium" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          href: `/answer/do/${data.value.appId}`
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode("去答题 ")
                          ])),
                          _: 1
                        }, 8, ["href"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (data.value.scoringStrategy !== 1)
                  ? (_openBlock(), _createBlock(_component_a_col, {
                      key: 0,
                      flex: "320px",
                      class: "content-wrapper"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_image, {
                          width: "100%",
                          src: data.value.resultPicture
                        }, null, 8, ["src"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
  ]))
}
}

})