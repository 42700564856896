import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "arco-upload-list-picture custom-upload-avatar"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "arco-upload-list-picture-mask" }
const _hoisted_4 = {
  key: 1,
  class: "arco-upload-picture-card"
}
const _hoisted_5 = { class: "arco-upload-picture-card-text" }

import { ref, watch } from "vue";
import { IconEdit, IconPlus } from "@arco-design/web-vue/es/icon";
import message from "@arco-design/web-vue/es/message";
import { uploadUsingPost } from "@/api/uploadController";
import { FileItem } from "@arco-design/web-vue";

// 用于控制上传按钮的加载状态

export default /*@__PURE__*/_defineComponent({
  __name: 'PictureUploader',
  props: {
    value: {}
  },
  emits: ["input"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const loading = ref(false);
const imageUrl = ref<string | null>();
const file = ref<FileItem | null>(null);
const disable = ref(false);
const emit = __emit;

const props = __props;

const isInitialLoad = ref(true);

watch(
  () => props.value,
  (newValue) => {
    if (newValue) {
      imageUrl.value = newValue;
      file.value = { url: newValue, status: "done", name: "existing-image" };
      disable.value = true;
      isInitialLoad.value = false;
    }
  }
);

// 清除文件和图片URL
const handleClear = () => {
  file.value = null; // 清除文件
  imageUrl.value = null; // 清除图片 URL
  disable.value = false; // 启用上传按钮
  emit("input", null); // 触发事件通知父组件
};

// 使用 defineExpose 将 handleClear 方法暴露给父组件
__expose({
  handleClear,
});

// onChange和onProgress用于渲染展示伪上传的图片
const onChange = (_: any, currentFile: FileItem) => {
  file.value = currentFile.url ? currentFile : null;
  if (currentFile.url) {
    disable.value = false;
  }
};

const onProgress = (currentFile: FileItem) => {
  file.value = currentFile.url ? currentFile : null;
};

const handleUpload = async () => {
  if (!file.value) {
    message.error("请先选择一个文件上传");
    return;
  }

  loading.value = true;
  const res: any = await uploadUsingPost({}, file.value.file);
  if (res.data.code === 0) {
    imageUrl.value = res.data.data;
    message.success("上传成功！记得点击提交按钮哦！");
    disable.value = true;
    loading.value = false;
    emit("input", imageUrl.value);
  } else {
    message.error("上传失败: " + res.data.message);
    loading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_progress = _resolveComponent("a-progress")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!

  return (_openBlock(), _createBlock(_component_a_space, {
    direction: "vertical",
    style: { width: '100%' }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_space, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_upload, {
            ref: "imageUrlRef",
            fileList: file.value ? [file.value] : [],
            "show-file-list": false,
            "auto-upload": false,
            onChange: onChange,
            onProgress: onProgress
          }, {
            "upload-button": _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(`arco-upload-list-item${
              file.value && file.value.status === 'error'
                ? ' arco-upload-list-item-error'
                : ''
            }`)
              }, [
                (file.value && file.value.url)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createElementVNode("img", {
                        src: file.value.url
                      }, null, 8, _hoisted_2),
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_unref(IconEdit))
                      ]),
                      (file.value.status === 'uploading' && file.value.percent < 100)
                        ? (_openBlock(), _createBlock(_component_a_progress, {
                            key: 0,
                            percent: file.value.percent,
                            type: "circle",
                            size: "mini",
                            style: {
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translateX(-50%) translateY(-50%)',
                }
                          }, null, 8, ["percent"]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_unref(IconPlus)),
                        _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"margin-top":"10px","font-weight":"600"} }, "上传图片", -1))
                      ])
                    ]))
              ], 2)
            ]),
            _: 1
          }, 8, ["fileList"]),
          _createVNode(_component_a_button, {
            type: "outline",
            size: "large",
            loading: loading.value,
            disabled: disable.value || !file.value,
            onClick: handleUpload,
            style: {"flex":"auto","margin-top":"50px"}
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("确认上传 ")
            ])),
            _: 1
          }, 8, ["loading", "disabled"]),
          _createVNode(_component_a_button, {
            type: "outline",
            size: "large",
            status: "warning",
            onClick: handleClear,
            style: {"flex":"auto","margin-top":"50px"}
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("清除图片 ")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})