import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  id: "updateUserPassword",
  style: {"display":"flex","flex-direction":"column","align-items":"center","justify-content":"center","height":"35vh"}
}

import { ref, computed } from "vue";
import API from "@/api";
import { updatePasswordUsingPost } from "@/api/userController";
import message from "@arco-design/web-vue/es/message";
import { useLoginUserStore } from "@/store/userStore";
import router from "@/router";
import ACCESS_ENUM from "@/access/accessEnum";


export default /*@__PURE__*/_defineComponent({
  __name: 'UpdateUserPassword',
  setup(__props, { expose: __expose }) {

const loginUserStore = useLoginUserStore();
const isLoading = ref(false);
const form = ref({
  newPassword: "",
  oldPassword: "",
  checkPassword: "",
} as API.UserUpdatePasswordRequest);

// 是否可见
const visible = ref(false);

// 打开弹窗
const openModal = () => {
  visible.value = true;
};

// 暴露函数给父组件
__expose({
  openModal,
});

// 关闭弹窗
const handleCancel = () => {
  visible.value = false;
};

/**
 * 提交修改密码请求
 */
const handleSubmit = async () => {
  isLoading.value = true;

  // 检查输入是否完整
  if (
    !form.value.oldPassword ||
    !form.value.newPassword ||
    !form.value.checkPassword
  ) {
    message.error("请输入完整信息！");
    isLoading.value = false;
    return;
  }

  // 检查新密码和确认密码是否一致
  if (passwordMismatch.value) {
    message.error("新密码和确认密码不一致！");
    isLoading.value = false;
    return;
  }

  const res = await updatePasswordUsingPost(form.value);
  if (res.data.code === 0) {
    message.success("修改成功！请重新登录...");
    loginUserStore.clearLoginState();
    // 延迟跳转至主页并刷新页面
    setTimeout(() => {
      router.replace("/").then(() => {
        window.location.reload();
      });
    }, 800);
  } else {
    message.error("修改失败，" + (res.data.message || "未知错误"));
  }

  isLoading.value = false;
};

/**
 * 计算属性，用于检测新密码和确认密码是否一致
 */
const passwordMismatch = computed(() => {
  return (
    form.value.newPassword !== form.value.checkPassword &&
    form.value.checkPassword !== ""
  );
});

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: visible.value,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((visible).value = $event)),
    title: "更改密码",
    footer: false,
    onCancel: handleCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_form, {
          model: form.value,
          style: { width: '450px' },
          "label-align": "left",
          "auto-label-width": "",
          onSubmit: handleSubmit
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "  用户账号：" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  modelValue: _unref(loginUserStore).loginUser.userAccount,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(loginUserStore).loginUser.userAccount) = $event)),
                  disabled: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "  用户昵称：" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  modelValue: _unref(loginUserStore).loginUser.userName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(loginUserStore).loginUser.userName) = $event)),
                  placeholder: "请输入用户名",
                  disabled: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              field: "oldPassword",
              label: "请输入旧密码：",
              rules: [{ required: true, message: '此项为必填' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input_password, {
                  modelValue: form.value.oldPassword,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.oldPassword) = $event)),
                  maxlength: 18
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              field: "newPassword",
              tooltip: "密码不小于8位",
              label: "请输入新密码：",
              rules: [{ required: true, message: '此项为必填' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input_password, {
                  modelValue: form.value.newPassword,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.newPassword) = $event)),
                  maxlength: 18
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              field: "checkPassword",
              tooltip: "密码不小于8位",
              label: "请确认新密码：",
              rules: [{ required: true, message: '此项为必填' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input_password, {
                  modelValue: form.value.checkPassword,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.checkPassword) = $event)),
                  maxlength: 18,
                  error: passwordMismatch.value
                }, null, 8, ["modelValue", "error"])
              ]),
              _: 1
            }),
            (passwordMismatch.value)
              ? (_openBlock(), _createBlock(_component_a_space, {
                  key: 0,
                  style: {"color":"#ff5454","margin":"0 auto"}
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("新密码和确认密码不一致 ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_a_button, {
              type: "primary",
              "html-type": "submit",
              style: {"width":"120px","margin":"0 auto"},
              disabled: passwordMismatch.value
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" 提交 ")
              ])),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        }, 8, ["model"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})