import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "addQuestionPage" }
const _hoisted_2 = {
  key: 0,
  class: "loading-container"
}

import { ref, watchEffect } from "vue";
import API from "@/api";
import { useRouter } from "vue-router";
import {
  addQuestionUsingPost,
  editQuestionUsingPost,
  listQuestionVoByPageUsingPost,
} from "@/api/questionController";
import message from "@arco-design/web-vue/es/message";
import AiGenerateQuestionDrawer from "@/views/add/components/AiGenerateQuestionDrawer.vue";

interface Props {
  appId: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddQuestionPage',
  props: {
    appId: { default: () => {
    return "";
  } }
  },
  setup(__props: any) {

const props = __props;

const router = useRouter();
const isLoading = ref(true);

// 题目内容结构（理解为题目列表）
const questionContent = ref<API.QuestionContentDTO[]>([]);

/**
 * 添加题目
 * @param index
 */
const addQuestion = (index: number) => {
  questionContent.value.splice(index, 0, {
    title: "",
    options: [],
  });
};

/**
 * 删除题目
 * @param index
 */
const deleteQuestion = (index: number) => {
  questionContent.value.splice(index, 1);
};

/**
 * 添加题目选项
 * @param question
 * @param index
 */
const addQuestionOption = (question: API.QuestionContentDTO, index: number) => {
  if (!question.options) {
    question.options = [];
  }
  question.options.splice(index, 0, {
    key: "",
    value: "",
  });
};

/**
 * 删除题目选项
 * @param question
 * @param index
 */
const deleteQuestionOption = (
  question: API.QuestionContentDTO,
  index: number
) => {
  if (!question.options) {
    question.options = [];
  }
  question.options.splice(index, 1);
};

const oldQuestion = ref<API.QuestionVO>();

/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.appId) {
    return;
  }
  const res = await listQuestionVoByPageUsingPost({
    appId: props.appId as any,
    current: 1,
    pageSize: 1,
    sortField: "createTime",
    sortOrder: "descend",
  });
  if (res.data.code === 0 && res.data.data?.records) {
    oldQuestion.value = res.data.data?.records[0];
    if (oldQuestion.value) {
      questionContent.value = oldQuestion.value.questionContent ?? [];
    }
    isLoading.value = false;
  } else {
    message.error("获取数据失败，" + res.data.message);
    isLoading.value = false;
  }
};

// 获取旧数据
watchEffect(() => {
  loadData();
});

const buttonDisable = ref(false);
/**
 * 提交
 */
const handleSubmit = async () => {
  if (!props.appId || !questionContent.value) {
    return;
  }
  buttonDisable.value = true;
  let res: any;
  // 如果是修改
  if (oldQuestion.value?.id) {
    res = await editQuestionUsingPost({
      id: oldQuestion.value.id,
      questionContent: questionContent.value,
    });
  } else {
    // 创建
    res = await addQuestionUsingPost({
      appId: props.appId as any,
      questionContent: questionContent.value,
    });
  }
  if (res.data.code === 0) {
    message.success("操作成功，即将跳转到应用详情页");
    buttonDisable.value = false;
    setTimeout(() => {
      router.push(`/app/detail/${props.appId}`);
    }, 800);
  } else {
    message.error("操作失败，" + res.data.message);
    buttonDisable.value = false;
  }
};

/**
 * AI 生成题目成功后执行
 */
const onAiGenerateSuccess = (result: API.QuestionContentDTO[]) => {
  message.success(`AI 生成题目成功，生成 ${result.length} 道题目`);
  questionContent.value = [...questionContent.value, ...result];
};

/**
 * AI 生成题目成功后执行（SSE）
 */
const onAiGenerateSuccessSSE = (result: API.QuestionContentDTO) => {
  questionContent.value = [...questionContent.value, result];
};

/**
 * SSE 开始生成
 * @param event
 */
const onSSEStart = (event: any) => {
  message.success("开始生成");
};

/**
 * SSE 生成完毕
 * @param event
 */
const onSSEClose = (event: any) => {
  message.success("生成完毕");
};

return (_ctx: any,_cache: any) => {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_icon_loading = _resolveComponent("icon-loading")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[9] || (_cache[9] = _createElementVNode("h2", { style: {"margin-bottom":"32px"} }, "设置题目", -1)),
    _createVNode(_component_a_form, {
      model: questionContent.value,
      style: { width: '480px' },
      "label-align": "left",
      "auto-label-width": "",
      onSubmit: handleSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, { label: "应用 id" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.appId), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "题目列表",
          "content-flex": false,
          "merge-props": false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_space, { size: "medium" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (addQuestion(questionContent.value.length)))
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" 底部添加题目 ")
                  ])),
                  _: 1
                }),
                _createVNode(AiGenerateQuestionDrawer, {
                  appId: _ctx.appId,
                  onSuccess: onAiGenerateSuccess,
                  onSSESuccess: onAiGenerateSuccessSSE,
                  onSSEClose: onSSEClose,
                  onSSEStart: onSSEStart
                }, null, 8, ["appId"]),
                _createVNode(_component_a_button, {
                  type: "primary",
                  "html-type": "submit",
                  style: {"width":"120px"},
                  disabled: buttonDisable.value
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" 提交 ")
                  ])),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            }),
            (isLoading.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _cache[3] || (_cache[3] = _createTextVNode(" 加载中，请稍候... ")),
                  _createVNode(_component_icon_loading)
                ]))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(questionContent.value, (question, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createVNode(_component_a_space, { size: "large" }, {
                      default: _withCtx(() => [
                        _createElementVNode("h3", null, "题目 " + _toDisplayString(index + 1), 1),
                        _createVNode(_component_a_button, {
                          size: "small",
                          onClick: ($event: any) => (addQuestion(index + 1))
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode(" 添加题目 ")
                          ])),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_a_button, {
                          size: "small",
                          status: "danger",
                          onClick: ($event: any) => (deleteQuestion(index))
                        }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode(" 删除题目 ")
                          ])),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_a_form_item, {
                      field: "posts.post1",
                      label: `题目 ${index + 1} 标题`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          modelValue: question.title,
                          "onUpdate:modelValue": ($event: any) => ((question.title) = $event),
                          placeholder: "请输入标题"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["label"]),
                    _createVNode(_component_a_space, { size: "large" }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, "题目 " + _toDisplayString(index + 1) + " 选项列表", 1),
                        _createVNode(_component_a_button, {
                          size: "small",
                          onClick: ($event: any) => (addQuestionOption(question, question.options.length))
                        }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode(" 底部添加选项 ")
                          ])),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (option, optionIndex) => {
                      return (_openBlock(), _createBlock(_component_a_form_item, {
                        key: optionIndex,
                        label: `选项 ${optionIndex + 1}`,
                        "content-flex": false,
                        "merge-props": false
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { label: "选项 key" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                modelValue: option.key,
                                "onUpdate:modelValue": ($event: any) => ((option.key) = $event),
                                placeholder: "请输入选项 key"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_a_form_item, { label: "选项值" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                modelValue: option.value,
                                "onUpdate:modelValue": ($event: any) => ((option.value) = $event),
                                placeholder: "请输入选项值"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_a_form_item, { label: "选项结果" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                modelValue: option.result,
                                "onUpdate:modelValue": ($event: any) => ((option.result) = $event),
                                placeholder: "请输入选项结果"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_a_form_item, { label: "选项得分" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input_number, {
                                modelValue: option.score,
                                "onUpdate:modelValue": ($event: any) => ((option.score) = $event),
                                placeholder: "请输入选项得分"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_a_space, { size: "large" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_button, {
                                size: "mini",
                                onClick: ($event: any) => (addQuestionOption(question, optionIndex + 1))
                              }, {
                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                  _createTextVNode(" 添加选项 ")
                                ])),
                                _: 2
                              }, 1032, ["onClick"]),
                              _createVNode(_component_a_button, {
                                size: "mini",
                                status: "danger",
                                onClick: ($event: any) => (deleteQuestionOption(question, optionIndex as any))
                              }, {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createTextVNode(" 删除选项 ")
                                ])),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["label"]))
                    }), 128))
                  ]))
                }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})