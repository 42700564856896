<template>
  <div id="UserLayout">
    <a-layout style="height: 100vh">
      <a-layout-header class="header">
        <img class="logo" src="../assets/logo.png" />
        <div>知巢智能应用平台</div>
      </a-layout-header>
      <a-layout-content class="content">
        <router-view />
      </a-layout-content>
      <a-layout-footer class="footer">
        <a-space>
          <a-link
            href="https://github.com/MrZboyan"
            target="_blank"
            :hoverable="false"
            >Created by MrZ
          </a-link>
        </a-space>
      </a-layout-footer>
    </a-layout>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
#UserLayout {
  text-align: center;
  background: url(../assets/LoginBackground.png) 0% 0% / 100% 100%;
}

#UserLayout .logo {
  width: 96px;
  height: 96px;
}

#UserLayout .header {
  margin-top: 32px;
  font-size: 40px;
}

#UserLayout .content {
  margin-bottom: 32px;
  padding: 40px;
}

.footer {
  padding: 16px;
  font-size: 20px;
  text-align: center;
  background: #efefef;
}
</style>
