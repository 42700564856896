import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading-container"
}

import { ref, watchEffect } from "vue";
import {
  deleteAppUsingPost,
  doAppReviewUsingPost,
  listAppByPageUsingPost,
} from "@/api/appController";
import API from "@/api";
import message from "@arco-design/web-vue/es/message";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import {
  APP_SCORING_STRATEGY_MAP,
  APP_TYPE_MAP,
  REVIEW_STATUS_ENUM,
  REVIEW_STATUS_MAP,
} from "@/constant/app";
import utc from "dayjs/plugin/utc";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminAppPage',
  setup(__props) {

const formSearchParams = ref<API.AppQueryRequest>({});

dayjs.extend(utc);

// 初始化搜索条件（不应该被修改）
const initSearchParams = {
  current: 1,
  pageSize: 10,
  sortField: "updateTime",
  sortOrder: "",
};

const searchParams = ref<API.AppQueryRequest>({
  ...initSearchParams,
});

const dataList = ref<API.App[]>([]);
const total = ref<number>(0);
const isLoading = ref(true);

/**
 * 加载数据
 */
const loadData = async () => {
  const res = await listAppByPageUsingPost(searchParams.value);
  if (res.data.code === 0) {
    dataList.value = res.data.data?.records || [];
    total.value = res.data.data?.total || 0;
    isLoading.value = false;
  } else {
    message.error("获取数据失败，" + res.data.message);
    isLoading.value = false;
  }
};

/**
 * 执行搜索
 */
const doSearch = () => {
  searchParams.value = {
    ...initSearchParams,
    ...formSearchParams.value,
  };
};

/**
 * 当分页变化时，改变搜索条件，触发数据加载
 * @param page
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

/**
 * 删除
 * @param record
 */
const doDelete = async (record: API.App) => {
  isLoading.value = true;
  if (!record.id) {
    return;
  }

  const res = await deleteAppUsingPost({
    id: record.id,
  });
  if (res.data.code === 0) {
    loadData();
    isLoading.value = false;
  } else {
    message.error("删除失败，" + res.data.message);
    isLoading.value = false;
  }
};

/**
 * 审核
 * @param record
 * @param reviewStatus
 * @param reviewMessage
 */
const doReview = async (
  record: API.App,
  reviewStatus: number,
  reviewMessage?: string
) => {
  if (!record.id) {
    return;
  }

  const res = await doAppReviewUsingPost({
    id: record.id,
    reviewStatus,
    reviewMessage,
  });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("审核失败，" + res.data.message);
  }
};

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});

// 表格列配置
const columns = [
  {
    title: "id",
    dataIndex: "id",
    align: "center",
    width: 115,
  },
  {
    title: "名称",
    dataIndex: "appName",
    align: "center",
    width: 100,
  },
  {
    title: "描述",
    dataIndex: "appDesc",
    align: "center",
    width: 110,
    tooltip: "true",
    ellipsis: "true",
  },
  {
    title: "图标",
    dataIndex: "appIcon",
    slotName: "appIcon",
    align: "center",
    width: 100,
  },
  {
    title: "应用类型",
    dataIndex: "appType",
    slotName: "appType",
    align: "center",
    width: 90,
  },
  {
    title: "评分策略",
    dataIndex: "scoringStrategy",
    slotName: "scoringStrategy",
    align: "center",
    width: 90,
  },
  {
    title: "审核状态",
    dataIndex: "reviewStatus",
    slotName: "reviewStatus",
    align: "center",
    width: 100,
  },
  {
    title: "审核时间",
    dataIndex: "reviewTime",
    slotName: "reviewTime",
    align: "center",
    width: 115,
  },
  {
    title: "审核人",
    dataIndex: "reviewerId",
    align: "center",
    width: 115,
  },
  {
    title: "用户 id",
    dataIndex: "userId",
    align: "center",
    width: 115,
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
    align: "center",
    width: 115,
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
    align: "center",
    width: 115,
  },
  {
    title: "操作",
    slotName: "optional",
    align: "center",
    minWidth: 250,
  },
];

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_icon_loading = _resolveComponent("icon-loading")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_form, {
      model: formSearchParams.value,
      style: { marginBottom: '20px' , display: 'flex', justifyContent: 'center'},
      layout: "inline",
      onSubmit: doSearch
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "appName",
          label: "应用名称"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: formSearchParams.value.appName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formSearchParams.value.appName) = $event)),
              placeholder: "请输入应用名称",
              "allow-clear": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "appDesc",
          label: "应用描述"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: formSearchParams.value.appDesc,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formSearchParams.value.appDesc) = $event)),
              placeholder: "请输入应用描述",
              "allow-clear": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              "html-type": "submit",
              style: {"width":"100px"}
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" 搜索 ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[3] || (_cache[3] = _createTextVNode(" 加载中，请稍候... ")),
          _createVNode(_component_icon_loading)
        ]))
      : (_openBlock(), _createBlock(_component_a_table, {
          key: 1,
          columns: columns,
          data: dataList.value,
          pagination: {
      showTotal: true,
      pageSize: searchParams.value.pageSize,
      current: searchParams.value.current,
      sortField: searchParams.value.sortField,
      sortOrder: searchParams.value.sortOrder,
      total: total.value,
    },
          onPageChange: onPageChange
        }, {
          appIcon: _withCtx(({ record }) => [
            _createVNode(_component_a_image, {
              width: "64",
              src: record.appIcon
            }, null, 8, ["src"])
          ]),
          appType: _withCtx(({ record }) => [
            _createTextVNode(_toDisplayString(_unref(APP_TYPE_MAP)[record.appType]), 1)
          ]),
          scoringStrategy: _withCtx(({ record }) => [
            _createTextVNode(_toDisplayString(_unref(APP_SCORING_STRATEGY_MAP)[record.scoringStrategy]), 1)
          ]),
          reviewStatus: _withCtx(({ record }) => [
            _createTextVNode(_toDisplayString(_unref(REVIEW_STATUS_MAP)[record.reviewStatus]), 1)
          ]),
          reviewTime: _withCtx(({ record }) => [
            _createTextVNode(_toDisplayString(record.reviewTime &&
        _unref(dayjs)(record.reviewTime).format("YYYY-MM-DD HH:mm:ss")), 1)
          ]),
          createTime: _withCtx(({ record }) => [
            _createTextVNode(_toDisplayString(_unref(dayjs).utc(record.createTime).format("YYYY-MM-DD HH:mm:ss")), 1)
          ]),
          updateTime: _withCtx(({ record }) => [
            _createTextVNode(_toDisplayString(_unref(dayjs).utc(record.updateTime).format("YYYY-MM-DD HH:mm:ss")), 1)
          ]),
          optional: _withCtx(({ record }) => [
            _createVNode(_component_a_space, null, {
              default: _withCtx(() => [
                (record.reviewStatus !== _unref(REVIEW_STATUS_ENUM).PASS)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 0,
                      status: "success",
                      onClick: ($event: any) => (doReview(record, _unref(REVIEW_STATUS_ENUM).PASS, '通过'))
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" 通过 ")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                (record.reviewStatus !== _unref(REVIEW_STATUS_ENUM).REJECT)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 1,
                      status: "warning",
                      onClick: ($event: any) => (doReview(record, _unref(REVIEW_STATUS_ENUM).REJECT, '不符合上架要求'))
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode(" 拒绝 ")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_a_popconfirm, {
                  content: "你确定要删除吗?",
                  type: "warning",
                  position: "right",
                  "ok-text": "确定",
                  onOk: ($event: any) => (doDelete(record)),
                  "ok-loading": isLoading.value
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, { status: "danger" }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode("删除")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onOk", "ok-loading"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["data", "pagination"]))
  ], 64))
}
}

})