import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../assets/logo.png'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }

import { routes } from "@/router/routes";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { useLoginUserStore } from "@/store/userStore";
import { userLogoutUsingPost } from "@/api/userController";
import message from "@arco-design/web-vue/es/message";
import UserCenter from "@/components/UserCenter.vue";
import UpdateUserPassword from "@/components/UpdateUserPassword.vue";
import ACCESS_ENUM from "@/access/accessEnum";


export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalHeader',
  setup(__props) {

const router = useRouter();
const userCenterRef = ref();
const userUpdatePasswordRef = ref();
const loginUserStore = useLoginUserStore();
const isLogin = ref(localStorage.getItem("hasFetched"));

// 当前选中的菜单项
const selectedKeys = ref(["/"]);
// 路由跳转时自动更新选中的菜单项
router.afterEach((to) => {
  selectedKeys.value = [to.path];
});
// 过滤掉隐藏在菜单中的路由
const visibleRoutes = routes.filter((item) => item.meta?.hideInMenu !== true);
// 点击跳转到对应的菜单页面
const handleMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};

// 退出登录
const handleLogout = async () => {
  try {
    // 调用后端的退出登录接口
    const res = await userLogoutUsingPost();

    if (res.data.code === 0) {
      message.success("登出成功，正在跳转...");

      // 清除本地存储的用户数据和状态
      localStorage.removeItem("hasFetched");

      // 重置 Pinia 中的用户状态
      loginUserStore.setLoginUser({ userRole: ACCESS_ENUM.NOT_LOGIN });
      loginUserStore.hasFetched = false;

      // 延时跳转至主页
      setTimeout(() => {
        router.replace("/").then(() => {
          // 刷新页面以确保清理 Session 状态
          window.location.reload();
        });
      }, 600);
    } else {
      message.error("登出失败：" + (res.data.message || "未知错误"));
    }
  } catch (error) {
    console.error("登出错误:", error);
    message.error("登出失败，请稍后重试");
  }
};

// 个人中心
const userCenterPage = (e: Event) => {
  if (userCenterRef.value) {
    userCenterRef.value.openModal();
  }
  // 阻止冒泡
  e.stopPropagation();
};

// 修改密码
const userUpdatePasswordPage = (e: Event) => {
  if (userUpdatePasswordRef.value) {
    userUpdatePasswordRef.value.openModal();
  }
  // 阻止冒泡
  e.stopPropagation();
};

return (_ctx: any,_cache: any) => {
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_icon_export = _resolveComponent("icon-export")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_skeleton_shape = _resolveComponent("a-skeleton-shape")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_icon_list = _resolveComponent("icon-list")!
  const _component_a_doption = _resolveComponent("a-doption")!
  const _component_icon_eye_invisible = _resolveComponent("icon-eye-invisible")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, {
    id: "globalHeader",
    align: "center",
    wrap: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, { flex: "auto" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_menu, {
            mode: "horizontal",
            "selected-keys": selectedKeys.value,
            onMenuItemClick: handleMenuClick
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_menu_item, {
                key: "0",
                style: { padding: 0, marginRight: '30px' },
                disabled: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_space, null, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createElementVNode("div", { class: "titleBar" }, [
                        _createElementVNode("img", {
                          class: "logo",
                          src: _imports_0
                        }),
                        _createElementVNode("div", { class: "title" }, "知巢")
                      ], -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(visibleRoutes), (item) => {
                return (_openBlock(), _createBlock(_component_a_menu_item, {
                  style: {"margin-right":"20px"},
                  class: "menu-item-font",
                  key: item.path
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["selected-keys"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, { flex: "150px" }, {
        default: _withCtx(() => [
          (isLogin.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_a_space, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_dropdown, { trigger: "hover" }, {
                      content: _withCtx(() => [
                        _createVNode(_component_a_doption, {
                          style: {"color":"#2ad0f9"},
                          onClick: userCenterPage
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_icon_list)
                          ]),
                          default: _withCtx(() => [
                            _cache[1] || (_cache[1] = _createTextVNode(" 个人中心 "))
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_doption, {
                          style: {"color":"orange"},
                          onClick: userUpdatePasswordPage
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_icon_eye_invisible)
                          ]),
                          default: _withCtx(() => [
                            _cache[2] || (_cache[2] = _createTextVNode(" 更改密码 "))
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_doption, {
                          style: {"color":"#fa3030"},
                          onClick: handleLogout
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_icon_export)
                          ]),
                          default: _withCtx(() => [
                            _cache[3] || (_cache[3] = _createTextVNode(" 退出登录 "))
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        (_unref(loginUserStore).loginUser.userAvatar)
                          ? (_openBlock(), _createBlock(_component_a_avatar, {
                              key: 0,
                              "trigger-type": "mask",
                              shape: "square",
                              size: 64,
                              onClick: handleLogout
                            }, {
                              "trigger-icon": _withCtx(() => [
                                _createVNode(_component_icon_export, { size: 32 })
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("img", {
                                  alt: "登出",
                                  src: _unref(loginUserStore).loginUser.userAvatar
                                }, null, 8, _hoisted_2)
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_a_skeleton, {
                              key: 1,
                              animation: true
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_skeleton_shape, { size: "large" })
                              ]),
                              _: 1
                            }))
                      ]),
                      _: 1
                    }),
                    _createVNode(UserCenter, {
                      ref_key: "userCenterRef",
                      ref: userCenterRef
                    }, null, 512),
                    _createVNode(UpdateUserPassword, {
                      ref_key: "userUpdatePasswordRef",
                      ref: userUpdatePasswordRef
                    }, null, 512)
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_a_button, {
                  class: "loginFontSize",
                  type: "primary",
                  href: "/user/login",
                  size: "large",
                  style: {"font-size":"large"}
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" 登录 ")
                  ])),
                  _: 1
                })
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})