import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "basicLayout" }

import GlobalHeader from "@/components/GlobalHeader.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'BasicLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_link = _resolveComponent("a-link")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_layout, { style: {"height":"100vh"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_header, { class: "header" }, {
          default: _withCtx(() => [
            _createVNode(GlobalHeader)
          ]),
          _: 1
        }),
        _createVNode(_component_a_layout_content, { class: "content" }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }),
        _createVNode(_component_a_layout_footer, { class: "footer" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_space, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_link, {
                  href: "https://github.com/MrZboyan",
                  target: "_blank",
                  hoverable: false
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("知巢智能应用平台 by MrZ ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})